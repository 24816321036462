<template>
  <div v-myTitle :data-title="textTitle">
    <div class="explain">
<!--    <h1 class="title">TACK</h1>-->
      <h1 class="title" style="margin-top: 100px">{{ this.$title }}</h1>
    <div class="scoreTitle" v-if="show == true">
      <div class="content">矩阵说明：</div>
      <div class="content" style="margin-top: 10px;text-indent: 28px">
        {{ this.$title }}旨在通过相关{{ this.$project }}技能的重要程度及目前{{ this.$project
        }}人员的技能水准，分析{{ this.$project
        }}人员存在哪些具体的培训及发展需求，帮助{{ this.$project
        }}人员及其上司明确须协力发展的方向。
      </div>
    </div>
    <div class="scoreTitle" v-if="show == false">
      <div class="content">评分说明：</div>
      <div class="content" style="margin-top: 10px; text-indent: 28px">
        每题即为相关{{ this.$project
        }}技能项，请您根据目前实际情况，评价您自身或您的下属（被评人姓名显示于页面左上方）在该项中的技能水准。
      </div>
      <div class="content" style="margin-top: 10px; text-indent: 28px">
        点击【开始评分】进行答题。请一次性完成评分，中途退出不保留之前记录。
      </div>
      <div class="content" style="margin-top: 10px">
        注：贵司组织方已完成相关技能水准对于当前职位的重要性评分。
      </div>
    </div>
    <button @click="next" v-if="show == true" class="btn">下一步</button>

    <button @click="start" v-if="show == false" class="btn">开始评分</button>
  </div>
  </div>
</template>

<script>
import { storeResult } from '@/request/api'

export default {
  name: "Explain",
  data() {
    return {
      textTitle: this.$title,
      show: true,
      userInfo: {},
    };
  },
  created() {
    this.userInfo =  this.$store.state.userInfo;
    if (!this.$store.state.userInfo) {
      this.$router.replace("/");
      return false;
    }
  },
  methods: {
    next() {
      this.show = false;
    },
    start() {
      console.log(this.userInfo.type, 'type')
      let staffId = 0;
      if (this.userInfo.type == 2) {
        let staffInfo = this.$store.state.staffInfo;
        staffId = staffInfo.id
      }
      storeResult({
        staff_id: staffId
      }).then((res) => {
        console.log(res.data, 'result_id')
        this.$store.commit("setResultId", res.data);
      });
      this.$router.replace("/exam");
    },
  },
};
</script>

<style>
.explain {
  background: url("../assets/img/index1.jpg");
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-size: cover;
  position: absolute;
}
.title {
  color: #0089c4;
  margin: 0;
}
.scoreTitle {
  width: 80%;
  height: 150px;
  margin: 24px auto;
  line-height: 22px;
  text-align: left;
}
.btn {
  height: 30px;
  width: max-content;
  padding: 2px 10px;
  margin-top: 120px;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 0 0 0 2px rgb(255, 255, 255), 0 0 5px 3px rgb(37, 161, 221);
  background-image: linear-gradient(#00B3F1, #00A3EF);
}
.content {
  font-size: 14px;
}
</style>
